<template lang="pug">

.about-page
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/img/final_3d_images/low/img023.jpg')",headline="Games to Level Up!!!")
	.container
		right-side-section(
			title="Lawn Tennis"
			:img='require("@/assets/img/final_3d_images/low/img027.jpg")'
			description=""
		)
			|<p>
			|  Tennis can be a great workout and lots of fun. Playing tennis
			|  has many health benefits including :
			|  <br />Increasing aerobic capacities <br />Lowering resting heart
			|  rate and blood pressure <br />Improving metabolic function
			|  <br />Increasing bone density <br />Lowering body fat
			|  <br />Improving muscle tone, strength and flexibility
			|  <br />Increasing reaction times.
			|  <br /><br /><span style="font-weight: 600;">Note:</span> Non marking shoes are compulsory for Lawn Tennis.
			|</p>
		
		left-side-section(
			title="Sand Volleyball"
			:img='require("@/assets/img/final_3d_images/low/img035.jpg")'
			description="Playing sand volleyball is not only challenging and enjoyable, but it’s also good for your health. The resistance that sand provides not only forces you to work harder, but it can also help promote a healthy cardiovascular system. The sand is a constant distraction, requiring continual feats of acrobatics and balance. Combine that with attempting to keep your attention focused on the ball, it makes for an excellent workout for the brain as well as the body. It’s true that sand volleyball is a super effective way to burn calories. A study from Harvard Medical School showed that an hour-long game of sand volleyball could burn up to almost 500 calories!"
		)

		right-side-section(
			title="Mini Football"
			:img='require("@/assets/img/final_3d_images/low/img085.jpg")'
			description="Football is world’s most popular participation sport. The actions performed in football are a result of multi-joint, multi-muscle movements, with muscles coordinating with each other to produce efficient movement, multi-directional forces and a stable structure to produce their movements. Football involves running, sprinting and drills that require generous energy expenditure and engage the cardiovascular system. Regularly participating in aerobic exercise such as football offers benefits such as increased longevity, as well as reduced susceptibility to heart disease, osteoporosis, diabetes, Improving heart health and blood pressure, Building strength, stamina and speed, Reducing body fat and improving concentration & coordination."
		)
		
		left-side-section(
			title="Box Cricket"
			:img='require("@/assets/overscans/cricket.jpg")'
			description="Cricket is a Vogue sport in India. We found it in every street of India. Box Cricket is a mini version of our beloved Cricket. It’s as much fun playing cricket in a box as in a stadium. There are new experiences to open yourself up to in our open space. Playing Cricket has many health benefits including ; Endurance & stamina, Balance & coordination, Physical fitness and Improving hand-eye coordination."
		)


</template>


<script>
import RightSideSection from '../components/XRightSideSection.vue'
import LeftSideSection from '../components/XLeftSideSection.vue'
import DisplayHeader from '../components/DisplayHeader.vue'
export default {
	components: {
	RightSideSection,
		DisplayHeader,
	LeftSideSection
  },
}
</script>

<style>
</style>